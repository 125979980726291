"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clipPaths = void 0;
exports.clipPaths = [
    "polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)",
    "polygon(50% 0%, 61% 35%, 98% 35%, 69% 57%, 78% 98%, 50% 78%, 22% 98%, 31% 57%, 2% 35%, 39% 35%)",
    "circle(50% at 77% 31%)",
    "circle(64.0% at 67% 50%)",
    "ellipse(25% 40% at 50% 50%)",
    "circle(50% at 50% 50%)",
    "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
    "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)",
    "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",
    "polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)",
    "polygon(0 0, 100% 0, 50% 100%, 0 100%)",
    "polygon(50% 0, 100% 0, 100% 100%, 0% 100%)",
    "polygon(0 0, 50% 0, 100% 100%, 0% 100%)",
    "polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 50% 99%)",
    "polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)",
    "polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)",
    "polygon(0 0, 100% 0, 100% 80%, 0 100%)",
    "polygon(0 0, 100% 0, 100% 100%, 0 80%)",
];
