import { Link } from "react-router-dom";
import styled from "styled-components";
import { forPhoneOnly, keyboardFocusOnly } from "./Mixins";

export const StyledA = styled.a`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;

    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledNavLink = styled(Link)`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;
    
    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledUserPicture = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
`

export const StyledDesc = styled.p`
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH1 = styled.h1`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH2 = styled.h2`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH3 = styled.h3`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH4 = styled.h4`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH5 = styled.h5`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledH6 = styled.h6`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledErrorText = styled.div`
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.danger};
`

export const StyledSmall = styled.small`
    font-size: 12px;
    position: relative;
    color: ${(props) => props.theme.colors.gray};
`

export const StyledIsRequired = styled.span`
    color: ${(props) => props.theme.colors.danger};
`

export const StyledTitle = styled.div`
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
`

export const StyledTitleDesc = styled.p`
    font-size: 0.9rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray4};
`

export const StyledTitleDescDiv = styled.div`
    font-size: 0.9rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray4};

    &.short {
      text-align: center;
      max-width: 700px;
      ${forPhoneOnly("max-width: 250px;")}
    }
`
