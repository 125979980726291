import Moment from "react-moment";
import { TFunction } from "i18next";
import styled from "styled-components";
import { Tooltip } from 'react-tooltip';
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/use-api";
import Card from "../../../components/Card/Card";
import { AuthContext } from "../../../context/auth";
import { Group } from "../../../layout/Group/Group";
import { forPhoneOnly, grow } from "../../../elements";
import { useForm } from "../../../hooks/use-form.hook";
import { Button } from "../../../components/Form/Button";
import { useNotify } from "../../../hooks/use-notify.hook";
import { useMutation, useQuery } from "@tanstack/react-query";
import OffCanvas from "../../../components/OffCanvas/OffCanvas";
import { TextInput } from "../../../components/Form/TextInput/TextInput";
import { SelectInput } from "../../../components/Form/Select/SelectInput";
import { Copy, CursorText, Globe2, Keyboard, Pencil, PlusLg, Search, Trash } from "react-bootstrap-icons";
import { enumValuesToArray, Status, Template, TemplateCategories, templateCategoriesArray } from "shared-library";

const StyledGroup = styled(Group)`
    padding: 20px;
    
    .add-container {
        cursor: pointer;
        font-size: 14px;
        padding: 80px 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
        ${forPhoneOnly("padding: 40px 15px;")}
        color: ${props => props.theme.colors.gray};
    }

    .search-input {
        width: 350px;
    }

    .bar {

        .navLink {
            font-size: 12px;
            cursor: pointer;
            padding-bottom: 4px;
            transition: all 0.5s;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.colors.black};

            &.active {
                border-bottom-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    .template-container {
        padding: 0px 10px;
    }
`;

const TemplateGroup = styled(Group)`
    overflow: hidden;
    flex-grow: 1;
    width: 300px;
    max-width: 300px;
    padding: 10px 0;
    ${grow}
    border: 1px solid ${props => props.theme.colors.gray10};
    ${forPhoneOnly("max-width: 95%; margin: 0 auto;")}

    &.deleting {
        opacity: 0.3;
        pointer-events: none;
    }

    .scrollable {
        overflow-y: auto;
        width: 100%;
        min-height: 350px;
        max-height: 350px;

        ${forPhoneOnly("max-height: 500px;")}
        box-sizing: content-box;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        img {
            width: 100%;
            margin-bottom: -10px;
        }

    }

    .details {
        padding: 10px;

        span:nth-child(1) {
            color: ${props => props.theme.colors.black};
            font-size: 16px;
            font-weight: bold;
        }

        span:nth-child(2), small {
            color: ${props => props.theme.colors.gray};
            font-size: 14px;
        }

        .icon {
            cursor: pointer;
            color: ${props => props.theme.colors.gray};
        }

        .categories {
            padding-top: 10px;
            border-top: 1px solid ${props => props.theme.colors.gray10};
            .category {
                font-size: 12px;
                padding: 6px 10px 0 10px;
                color: ${props => props.theme.colors.black};
                border: 1px solid ${props => props.theme.colors.gray10};

                &.add {
                    cursor: pointer;
                    padding: 6px 6px 0 6px;
                }
            }
        }
    }
`
interface DropdownValues { label: string, value: TemplateCategories }

const categoriesPrepped = (t: TFunction<"translation", undefined>) => {
    const response: Array<DropdownValues> = [];

    for (const key of templateCategoriesArray) {
        let value = key as TemplateCategories;
        response.push({
            value,
            label: t(value),
        });
    }
    return response;
};

const AdminTemplates = () => {

    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const { state, setPreferences } = useContext(AuthContext);
    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);

    return (
        <StyledGroup className="container-fluid">
            <TemplateCreatorEditor offCanvasIsOpen={offCanvasIsOpen} setOffCanvasIsOpen={setOffCanvasIsOpen} />
            <Group gap="md" className="container">

                <Group direction="row">
                    <Card className="add-container" onClick={() => setOffCanvasIsOpen(!offCanvasIsOpen)}>
                        <Group direction="row" wrap="nowrap">
                            <PlusLg size={18} />
                            {t('newTemplate')}
                        </Group>
                    </Card>
                </Group>
                <Group direction="row" justify="space-between" className="bar">
                    <TextInput
                        isRequired
                        type="text"
                        value={query}
                        onChange={setQuery}
                        className="search-input"
                        placeholder={t('search')}
                        leftIcon={<Search size={18} />}
                    />
                    <Group direction="row" align="center" gap="sm" justify="center">
                        {
                            ([Status.draft, Status.active, Status.inactive]).map(status => <small key={status} onClick={() => setPreferences({ ...state.preferences, adminTemplateStatus: status })} className={"navLink" + (state.preferences.adminTemplateStatus === status ? ' active' : '')}>{t(status)}</small>)
                        }
                    </Group>
                </Group>

                <Group className="template-container" direction="row" gap="md">
                    {
                        state.resources.adminTemplates && state.resources.adminTemplates.filter(t => t.status === state.preferences.adminTemplateStatus && (!query.trim() || JSON.stringify(t).toLowerCase().includes(query.toLowerCase()))).map(template => <TemplateDom key={template.id} template={template} />)
                    }
                </Group>

            </Group>
        </StyledGroup>
    )
}

export default AdminTemplates;


const TemplateDom = ({ template }: { template: Template }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { confirm } = useNotify();
    const { deleteAdminTemplate } = useApi();
    const [stateTemplate] = useState({ id: template.id, title: template.title, shortId: template.shortId, status: { label: t(template.status), value: template.status }, categories: categoriesPrepped(t).filter(c => template.categories.includes(c.value)) });
    const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
    const [templateCopyOffCanvasIsOpen, setTemplateCopyOffCanvasIsOpen] = useState(false);
    const { isPending: isDeletingTemplate, mutateAsync: deleteAdminTemplateAsync } = useMutation({
        mutationFn: deleteAdminTemplate
    })

    return (
        <TemplateGroup gap="none" className={isDeletingTemplate ? "deleting" : ""}>
            <TemplateCreatorEditor offCanvasIsOpen={offCanvasIsOpen} setOffCanvasIsOpen={setOffCanvasIsOpen} template={stateTemplate} />
            <TemplateDuplicator offCanvasIsOpen={templateCopyOffCanvasIsOpen} setOffCanvasIsOpen={setTemplateCopyOffCanvasIsOpen} template={template} />

            <div className="scrollable">
                <img alt={t('templateScreenshot')} src={template.thumbnails.desktopFullPage || "/assets/images/diy-website-builder-dummy-image.png"} />
            </div>
            <Group className="details">
                <Group direction="row" wrap="nowrap" justify="space-between">
                    <Group gap="none">
                        <span>{template.title}</span>
                        <small>{template.shortId}</small>
                    </Group>
                    <Group gap="none" align="end">
                        <small>{t(template.status)}</small>
                        <small><Moment format="hh:mm, d MMM">{template.creationDate}</Moment></small>
                    </Group>
                </Group>
                <Group direction="row" align="center" justify="space-between">
                    <Pencil size={20} onClick={() => navigate(`/admin/templates/${template.shortId}`)} className="icon edit-icon" />
                    <Copy size={20} onClick={() => setTemplateCopyOffCanvasIsOpen(true)} className="icon duplicate-icon" />
                    {
                        template.fullURL ? <Globe2 size={20} onClick={() => window.open(template.fullURL, '_blank')?.focus()} className="icon globe-icon" /> : null
                    }
                    <Trash size={20} className="icon trash-icon" onClick={() => confirm({
                        onConfirmation: () => deleteAdminTemplateAsync(template.id)
                    })} />
                    <Tooltip anchorSelect=".edit-icon" place="top">{t('editProject')}</Tooltip>
                    <Tooltip anchorSelect=".duplicate-icon" place="top">{t('duplicateProject')}</Tooltip>
                    <Tooltip anchorSelect=".globe-icon" place="top">{t('preview')}</Tooltip>
                    <Tooltip anchorSelect=".trash-icon" place="top">{t('deleteProject')}</Tooltip>
                </Group>
                <Group className="categories" gap="xs" direction="row">
                    {
                        template.categories.map(category => <div key={category} className="category">{t(category)}</div>)
                    }
                    <Group onClick={() => setOffCanvasIsOpen(true)} className="category add" direction="row" gap="xs" justify="center" wrap="nowrap"><span><Pencil /></span></Group>
                </Group>
            </Group>
        </TemplateGroup>
    )
}

interface TemplateCreatorEditorProps {
    offCanvasIsOpen: boolean;
    template?: {
        id?: string;
        title: string;
        shortId: string;
        status: { value: Status; label: string; };
        categories: { value: TemplateCategories; label: string; }[];
    };
    setOffCanvasIsOpen: (v: boolean) => void;
}
const TemplateCreatorEditor = ({
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    template
}: TemplateCreatorEditorProps) => {

    const { t } = useTranslation();
    if (!template) {
        template = {
            title: "",
            shortId: "",
            categories: [],
            status: { label: t(Status.draft), value: Status.draft },
        }
    }

    const { state } = useContext(AuthContext);
    const { createAdminTemplate, getAdminTemplates } = useApi();
    const { onSubmit, onChange, errors, formValues, reset } = useForm({
        defaultValues: template,
        validations: {
            title: { required: true },
            shortId: { required: true },
            categories: { required: true, min: 1 },
        }
    })

    const { isPending: isCreatingTemplate, mutateAsync: createAdminTemplateAsync } = useMutation({
        mutationFn: createAdminTemplate
    })

    const { refetch } = useQuery({
        queryFn: getAdminTemplates,
        queryKey: ["adminTemplates"],
        enabled: !state.resources.adminTemplates?.length
    });

    const createTemplate = async () => {
        try {
            await createAdminTemplateAsync({ shortId: formValues.shortId, id: formValues.id, status: enumValuesToArray(Status).find(s => s === formValues.status.value) as Status, title: formValues.title, categories: formValues.categories.map(({ value }) => value) })
            refetch()
            reset()
            setOffCanvasIsOpen(false)
        } catch (error) {
        }
    };
    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t(!template.id ? 'newTemplate' : 'editTemplate')}
            backdrop={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <form onSubmit={onSubmit(createTemplate)}>
                <Group>
                    <TextInput
                        isRequired
                        type="text"
                        error={errors.shortId}
                        readonly={!!template.id}
                        placeholder={t('shortId')}
                        value={formValues.shortId}
                        leftIcon={<Keyboard size={16} />}
                        onChange={onChange("shortId")}
                    />
                    <TextInput
                        isRequired
                        type="text"
                        error={errors.title}
                        placeholder={t('title')}
                        value={formValues.title}
                        leftIcon={<CursorText size={16} />}
                        onChange={onChange("title")}
                    />
                    <SelectInput
                        isMulti={true}
                        error={errors.categories}
                        options={categoriesPrepped(t)}
                        placeholder={t('categories')}
                        onChange={onChange("categories")}
                        value={formValues.categories as any}
                    />
                    {
                        template.id ? (
                            <SelectInput
                                error={errors.status}
                                closeMenuOnSelect={true}
                                placeholder={t('status')}
                                onChange={onChange("status")}
                                value={formValues.status as any}
                                options={enumValuesToArray(Status).map(status => ({ label: t(status), value: status }))}
                            />
                        ) : null
                    }
                    <Button text={t(!template.id ? 'create' : 'update')} type="submit" disabled={isCreatingTemplate} loading={isCreatingTemplate} />
                </Group>
            </form>
        </OffCanvas>
    )
}

interface TemplateDuplicatorProps {
    template: Template;
    offCanvasIsOpen: boolean;
    setOffCanvasIsOpen: (v: boolean) => void;
}
const TemplateDuplicator = ({
    offCanvasIsOpen,
    setOffCanvasIsOpen,
    template
}: TemplateDuplicatorProps) => {

    const { t } = useTranslation();
    const { duplicateAdminTemplate } = useApi();
    const { onSubmit, onChange, errors, formValues, reset } = useForm({
        defaultValues: {
            shortId: `${template.shortId}-copy`,
        },
        validations: {
            shortId: { required: true },
        }
    })

    const { isPending, mutateAsync: duplicateAdminTemplateAsync } = useMutation({
        mutationFn: (shortId: string) => duplicateAdminTemplate(shortId, template)
    })

    const duplicateTemplate = async () => {
        try {
            await duplicateAdminTemplateAsync(formValues.shortId)
            reset()
            setOffCanvasIsOpen(false)
        } catch (error) {
        }
    };
    return (
        <OffCanvas
            isOpen={offCanvasIsOpen}
            title={t('duplicateTemplate')}
            backdrop={false}
            handleClose={() => setOffCanvasIsOpen(false)}
        >
            <form onSubmit={onSubmit(duplicateTemplate)}>
                <Group>
                    <TextInput
                        isRequired
                        type="text"
                        error={errors.shortId}
                        placeholder={t('shortId')}
                        value={formValues.shortId}
                        leftIcon={<Keyboard size={16} />}
                        onChange={onChange("shortId")}
                    />

                    <Button text={t('duplicate')} type="submit" disabled={isPending} loading={isPending} />
                </Group>
            </form>
        </OffCanvas>
    )
}