import { ReactNode } from "react";
import styled from "styled-components";
import { Group } from "../../layout/Group/Group";
import { growSmaller, transition } from "../../elements";

const StyledGroup = styled(Group)`
    border: 1px solid ${props => props.theme.colors.gray10};
    padding: 20px;
    border-radius: 4px;
    ${growSmaller}
    ${transition}
    animation: slideInUp 0.5s ease-in-out;
    text-align: center;
    
    &:hover { 
        border-radius: 2px;
    }

    &.pointer {
        cursor: pointer;
    }

    @keyframes slideInUp {
        from {
            transform: translateY(4px);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`

interface CardProps {
    className?: string;
    children: ReactNode;
    onClick?: () => void;
}
const Card = ({ children, onClick, className = "" }: CardProps) => {
    return (
        <StyledGroup align="center" gap="xs" justify="center" className={`${onClick ? 'pointer' : ''} ${className}`} onClick={onClick}>
            {children}
        </StyledGroup>
    )
}

export default Card;