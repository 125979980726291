"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVideoPlatform = void 0;
const lodash_1 = require("lodash");
const getVideoPlatform = (url) => {
    if ((0, lodash_1.isEmpty)(url))
        return;
    const platforms = {
        vimeo: /^https:\/\/vimeo\.com\/(\d+)$/,
        twitch: /^https:\/\/www\.twitch\.tv\/([a-zA-Z0-9_]+)$/,
        googleDrive: /https:\/\/drive\.google\.com\/file\/d\/\w+\/view/,
        tiktok: /https:\/\/www\.tiktok\.com\/@(?:[^\/]+\/)?video\/\w+/,
        facebook: /https:\/\/www\.facebook\.com\/(?:[^\/]+\/videos\/\d+|\?v=\d+)/,
        youtube: /https:\/\/(?:www\.|m\.)(?:youtube\.com\/watch\?v=|youtube\.com\/shorts\/)(?:\w+|\?feature=\w+&v=\w+)/
    };
    for (const platform in platforms) {
        if (platforms[platform].test(url))
            return platform;
    }
    return;
};
exports.getVideoPlatform = getVideoPlatform;
