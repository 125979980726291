import styled from "styled-components"
import { randomID } from "shared-library"
import { forPhoneOnly } from "../../elements"
import { useCallback, useEffect, useRef, useState } from "react"

const StyledOffCanvas = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: ${props => props.theme.colors.white};
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
    
    @media (prefers-reduced-motion: reduce) {
        transform: none !important;
    }
    
    &.show {
        transform: none !important;
    }
    
    &.top {
        top: 0;
        right: 0;
        left: 0;
        height: 30vh;
        max-height: 100%;
        border-bottom: 1px solid ${props => props.theme.colors.gray10};
        transform: translateY(-100%);
    }
    
    &.right {
        top: 0;
        right: 0;
        width: 20vw;
        ${forPhoneOnly("width: 100%;")}
        border-left: 1px solid ${props => props.theme.colors.gray10};
        transform: translateX(100%);
    }
    
    &.bottom {
        right: 0;
        left: 0;
        height: 30vh;
        max-height: 100%;
        border-top: 1px solid ${props => props.theme.colors.gray10};
        transform: translateY(100%);
    }
    
    &.left {
        top: 0;
        left: 0;
        width: 20vw;
        border-right: 1px solid ${props => props.theme.colors.gray10};
        transform: translateX(-100%);
    }
    
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }
    
    .title {
        margin: 0;
        line-height: 1.5;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: ${props => props.theme.colors.black};
    }
    
    .close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
        border: 0;
        border-radius: 0.25rem;
        opacity: 0.5;
        cursor: pointer;
    }
    
    .close:hover {
        color: #000;
        text-decoration: none;
        opacity: 0.75;
    }
    
    .close:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        opacity: 1;
    }
    
    .body {
        flex-grow: 1;
        padding: 1rem 1rem;
        overflow-y: auto;
    }
    
    .backdrop {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
    
    .backdrop.show {
        visibility: visible;
        opacity: 1;
    }
  
`

declare type Position = 'left' | 'right' | 'top' | 'bottom'
declare type Component = 'button' | 'div'
interface OffCanvasProps {
    title: string
    randomId?: string
    position?: Position
    backdrop?: boolean
    allowClickAway?: boolean
    allowEsc?: boolean
    allowScroll?: boolean
    component?: Component
    className?: string
    isOpen?: boolean;
    children: React.ReactNode
    styles?: React.CSSProperties
    handleClose?: () => void
}
const OffCanvas = ({
    isOpen = false,
    randomId = randomID(),
    title,
    position = 'right',
    backdrop = true,
    allowClickAway = true,
    allowEsc = true,
    allowScroll = true,
    className = 'wbl-offcanvas',
    styles = {},
    handleClose,
    children
}: OffCanvasProps) => {

    const [isTrigger, setIsTrigger] = useState(true)
    const profileDropDownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (allowClickAway && isOpen && !isTrigger && handleClose && profileDropDownRef.current && !profileDropDownRef.current.contains(event.target as Node)) {
            handleClose()
        }
    }, [allowClickAway, handleClose, isOpen, isTrigger])

    const onEscKey = useCallback(
        (event: KeyboardEvent) => {
            if (!allowEsc) return

            if (event.key === 'Escape') {
                if (isOpen) {
                    if (handleClose) handleClose()
                }
            }
        },
        [allowEsc, isOpen, handleClose]
    )

    useEffect(() => {
        document.addEventListener('keydown', onEscKey, false)
        return () => document.removeEventListener('keydown', onEscKey)
    }, [onEscKey])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    useEffect(() => {
        if (!allowScroll) {
            if (isOpen) document.body.style.overflow = 'hidden'
        }
        return () => {
            document.body.style.overflow = ''
        }
    }, [isOpen, allowScroll])

    useEffect(() => setIsTrigger(!isOpen), [isOpen])

    return (
        <>
            <StyledOffCanvas ref={profileDropDownRef}
                id={`offcanvas_${randomId}`}
                className={`${position} ${isOpen ? 'show' : ''
                    } ${className}`}
                tabIndex={-1}
                style={styles}
                role="dialog"
                aria-labelledby={'title'}
                aria-modal="true"
                onClick={(event) => event.stopPropagation()}
                aria-hidden="true"
            >
                <div className="header">
                    <h5 className="title">{title}</h5>
                    <button
                        className="close"
                        onClick={handleClose}
                        type="button"
                        tabIndex={0}
                        aria-label="Close"
                    />
                </div>
                <div className="body">
                    {children}
                </div>
            </StyledOffCanvas>
            {backdrop && (
                <div className={`backdrop ${isOpen ? 'show' : ''}`} />
            )}
        </>
    )
}

export default OffCanvas;