import { useContext } from "react";
import styled from "styled-components";
import { UserType } from "shared-library";
import Card from "../../components/Card/Card";
import { forPhoneOnly } from "../../elements";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import { CodeSlash, Gear, Person } from "react-bootstrap-icons";

const StyledGroup = styled(Group)`
    min-height: 100vh;

    p:nth-child(2) {
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 6px;
        text-align: center;
        text-transform: uppercase;
        color: ${props => props.theme.colors.gray};
    }

    p:nth-child(3) {
        font-size: 24px;
        margin-top: -12px;
        text-align: center;
        color: ${props => props.theme.colors.gray};
    }

    .card {
        max-width: 300px;
        ${forPhoneOnly("max-width: 100%; width: 70%;")}
        color: ${props => props.theme.colors.gray};

        .icon {
            color: ${props => props.theme.colors.gray10};
        }

        span {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 4px;
        }

        div {
            font-size: 14px;
        }
    }
`

export const ProfilePicker = () => {

    const navigate = useNavigate()
    const { t } = useTranslation();
    const { state, setUser } = useContext(AuthContext)

    const activateProfile = (currentUserType: UserType) => {
        // Call API to activate the profile
        if (state.user) {
            setUser({ ...state.user, currentUserType })
            navigate(`/${currentUserType}/dashboard`)
        }
    }

    return (
        <StyledGroup gap="xs" direction="column" align="center" justify="center">
            <img src="/logo.png" width="160" alt="Company logo" />
            <p>{t('welcomeBack')} {state.user?.fullName}</p>
            <p>{t('startBuildingYourDreamWebsiteToday')}</p>
            <br />
            <Group direction="row" justify="center">
                {
                    state.user?.userTypes.includes("admin") && <Card className="card" onClick={() => activateProfile("admin")}>
                        <Gear size={30} className="icon" />
                        <span>{t('admin').toUpperCase()}</span>
                        <div>
                            {t('manageTemplatesResources')}
                        </div>
                    </Card>
                }

                {
                    state.user?.userTypes.includes("user") && <Card className="card" onClick={() => activateProfile("user")}>
                        <Person size={30} className="icon" />
                        <span>{t('user').toUpperCase()}</span>
                        <div>
                            {t('manageYourProjectsUsingThePlatformsTool')}
                        </div>
                    </Card>
                }

                {
                    state.user?.userTypes.includes("developer") && <Card className="card" onClick={() => activateProfile("developer")}>
                        <CodeSlash size={30} className="icon" />
                        <span>{t('developer').toUpperCase()}</span>
                        <div>
                            {t('manageSDKSubscriptionSettings')}
                        </div>
                    </Card>
                }
            </Group>
        </StyledGroup>
    )
};