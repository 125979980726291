import Moment from "react-moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { growSmaller } from "../../elements";
import { Group } from "../../layout/Group/Group";
import { Facebook, Instagram, Linkedin, TwitterX, Youtube } from "react-bootstrap-icons";

const StyledGroup = styled(Group)`
    padding: 50px;
    margin-top: 100px;
    border-top: 1px solid ${props => props.theme.colors.gray10};
    ${growSmaller}

    .footer {

        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid ${props => props.theme.colors.gray10};

        .social-icon {
            cursor: pointer;
            color: ${props => props.theme.colors.gray};
        }

        a {
            font-size: 12px;
            text-decoration: none;
            color: ${props => props.theme.colors.gray};
        }
    }

    .footer-base {
        text-decoration: none;
        font-size: 12px;
        color: ${props => props.theme.colors.gray};

        a {
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px dotted ${props => props.theme.colors.gray};
            color: ${props => props.theme.colors.gray};
        }
    }
`;
const Footer = () => {
    return <StyledGroup>
        <Group className="footer container-fluid" align="center" direction="row" justify="space-between">
            <Link to="/" role="logo">
                <img src="/logo.png" width="160" alt="Company logo" />
            </Link>
            <Group direction="row" align="center">
                <Link to="/">Get Started</Link>
                <Link to="/">Contact Us</Link>
                <Link to="/">Support Center</Link>
                <Link to="/">Blog</Link>
                <Link to="/">User Guide</Link>
            </Group>
            <Group direction="row" wrap="nowrap">
                <Facebook className="social-icon" />
                <Instagram className="social-icon" />
                <TwitterX className="social-icon" />
                <Linkedin className="social-icon" />
                <Youtube className="social-icon" />
            </Group>
        </Group>
        <Group className="footer-base" gap="sm" direction="row" align="center" justify="center">
            <span>© <Moment format="YYYY" /> DIY Website Builder. All rights reserved.</span>
            <Link to="/">Privacy Policy</Link>
            <Link to="/">Terms of Service</Link>
            <Link to="/">Cookies Settings</Link>
        </Group>
    </StyledGroup>
}

export default Footer;