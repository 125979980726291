"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INVALID_URL"] = "INVALID_URL";
    ErrorCodes["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorCodes["UPDATE_FAILED"] = "UPDATE_FAILED";
    ErrorCodes["ACCESS_DENIED"] = "ACCESS_DENIED";
    ErrorCodes["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorCodes["INVALID_REQUEST"] = "INVALID_REQUEST";
    ErrorCodes["INVALID_SHORT_ID"] = "INVALID_SHORT_ID";
    ErrorCodes["PROJECT_NOT_FOUND"] = "PROJECT_NOT_FOUND";
    ErrorCodes["TEMPLATE_NOT_FOUND"] = "TEMPLATE_NOT_FOUND";
    ErrorCodes["NO_UPDATABLE_PARAM"] = "NO_UPDATABLE_PARAM";
    ErrorCodes["FILE_UPLOAD_FAILED"] = "FILE_UPLOAD_FAILED";
    ErrorCodes["FORBIDDEN_SHORT_ID"] = "FORBIDDEN_SHORT_ID";
    ErrorCodes["ERROR_CREATING_USER"] = "ERROR_CREATING_USER";
    ErrorCodes["ERROR_SAVING_ZIP_FILE"] = "ERROR_SAVING_ZIP_FILE";
    ErrorCodes["SERVICE_NOT_AVAILABLE"] = "SERVICE_NOT_AVAILABLE";
    ErrorCodes["PROJECT_NOT_PUBLISHED"] = "PROJECT_NOT_PUBLISHED";
    ErrorCodes["SHORT_ID_NOT_AVAILABLE"] = "SHORT_ID_NOT_AVAILABLE";
    ErrorCodes["ACCOUNT_ALREADY_EXISTS"] = "ACCOUNT_ALREADY_EXISTS";
    ErrorCodes["ERROR_PUBLISHING_PROJECT"] = "ERROR_PUBLISHING_PROJECT";
    ErrorCodes["MAXIMUM_PROJECT_EXCEEDED"] = "MAXIMUM_PROJECT_EXCEEDED";
    ErrorCodes["ERROR_DUPLICATING_PROJECT"] = "ERROR_DUPLICATING_PROJECT";
    ErrorCodes["UNKNOWN_FILE_CONTENT_TYPE"] = "UNKNOWN_FILE_CONTENT_TYPE";
    ErrorCodes["INVALID_AUTHENTICATION_CODE"] = "INVALID_AUTHENTICATION_CODE";
    ErrorCodes["HREF_FIELD_FAILED_VALIDATION"] = "HREF_FIELD_FAILED_VALIDATION";
    ErrorCodes["ICON_FIELD_FAILED_VALIDATION"] = "ICON_FIELD_FAILED_VALIDATION";
    ErrorCodes["FONT_FIELD_FAILED_VALIDATION"] = "FONT_FIELD_FAILED_VALIDATION";
    ErrorCodes["IMAGE_FIELD_FAILED_VALIDATION"] = "IMAGE_FIELD_FAILED_VALIDATION";
    ErrorCodes["EMAIL_FIELD_FAILED_VALIDATION"] = "EMAIL_FIELD_FAILED_VALIDATION";
    ErrorCodes["FILE_UPLOAD_FAILED_VALIDATION"] = "FILE_UPLOAD_FAILED_VALIDATION";
    ErrorCodes["REQUEST_BODY_FAILED_VALIDATION"] = "REQUEST_BODY_FAILED_VALIDATION";
    ErrorCodes["BORDER_FIELD_FAILED_VALIDATION"] = "BORDER_FIELD_FAILED_VALIDATION";
    ErrorCodes["HEIGHT_FIELD_FAILED_VALIDATION"] = "HEIGHT_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_FIELD_FAILED_VALIDATION"] = "PROJECT_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_FIELD_FAILED_VALIDATION"] = "DOCUMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["CLIP_PATH_FIELD_FAILED_VALIDATION"] = "CLIP_PATH_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["BACKGROUND_FIELD_FAILED_VALIDATION"] = "BACKGROUND_FIELD_FAILED_VALIDATION";
    ErrorCodes["MEASUREMENT_FIELD_FAILED_VALIDATION"] = "MEASUREMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["IMAGE_FILTER_FIELD_FAILED_VALIDATION"] = "IMAGE_FILTER_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_ASSET_FIELD_FAILED_VALIDATION"] = "PROJECT_ASSET_FIELD_FAILED_VALIDATION";
    ErrorCodes["COLOR_PATTERN_FIELD_FAILED_VALIDATION"] = "COLOR_PATTERN_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_MAIN_FIELD_FAILED_VALIDATION"] = "COMPONENT_MAIN_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_PRESET_FIELD_FAILED_VALIDATION"] = "DOCUMENT_PRESET_FIELD_FAILED_VALIDATION";
    ErrorCodes["AUTHENTICATION_FIELDS_FAILED_VALIDATION"] = "AUTHENTICATION_FIELDS_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_PARENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_PARENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_ELEMENT_FIELD_FAILED_VALIDATION"] = "COMPONENT_ELEMENT_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["COMPONENT_ELEMENT_IMAGE_FIELD_FAILED_VALIDATION"] = "COMPONENT_ELEMENT_IMAGE_FIELD_FAILED_VALIDATION";
    ErrorCodes["PROJECT_ASSET_FIELD_CROP_DATA_FAILED_VALIDATION"] = "PROJECT_ASSET_FIELD_CROP_DATA_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_NAVBAR_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_NAVBAR_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_LIST_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_LIST_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_TEXT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_TEXT_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_ICONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_ICONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_VIDEO_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_VIDEO_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_LINKS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_LINKS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_IMAGE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_IMAGE_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_BUTTONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_BUTTONS_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_DIVIDER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_DIVIDER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CONTROL_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CONTROL_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CONTAINER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CONTAINER_ELEMENT_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_HOVER_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_HOVER_SETTINGS_FIELD_FAILED_VALIDATION";
    ErrorCodes["DOCUMENT_COMPONENT_CHILDREN_ANIMATION_ENTRANCE_SETTINGS_FIELD_FAILED_VALIDATION"] = "DOCUMENT_COMPONENT_CHILDREN_ANIMATION_ENTRANCE_SETTINGS_FIELD_FAILED_VALIDATION";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
