import { AuthContext } from "../auth";
import { ErrorCodes } from "shared-library";
import { useTranslation } from "react-i18next";
import { ReactNode, useContext, useMemo } from "react";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { DefaultOptions, MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: true,
    retry: 1,
  },
};

export const ReactQueryContextProvider = ({ children }: { children: ReactNode }) => {

  const { t } = useTranslation();
  const { log } = useConsoleLog();
  const { notify } = useNotification();
  const { logout } = useContext(AuthContext)

  const notifyError = {
    type: "error",
    title: t('unexpectedError'),
    body: t('serviceNotAvailable'),
  } as const;

  const queryClient = useMemo(() => {
    const onError = (e: any) => {

      if ([ErrorCodes.SERVICE_NOT_AVAILABLE].includes(e) || "Failed to fetch" === e?.message) {
        logout()
        return
      }

      //TODO:: Implement language context
      if (!t([e as ErrorCodes])) {
        // Unknown error
        if (typeof e === "string") {
          notify({ ...notifyError, body: e });
        } else {
          notify(notifyError);
        }
        return
      }
      notify({ ...notifyError, body: t([e as ErrorCodes]) });
      log(e);
    };

    const queryCache = new QueryCache({ onError });
    const mutationCache = new MutationCache({ onError });

    return new QueryClient({ defaultOptions, queryCache, mutationCache });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, notify]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
