import { Config } from "../../config";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth";
import DIYWebsiteBuilder from 'diy-website-builder-lib';
import { useLocation, useParams } from "react-router-dom";


const Build = () => {

    const location = useLocation()
    const { shortId } = useParams();
    const { state, setResources } = useContext(AuthContext);

    useEffect(() => {
        const container = document.getElementById('container')
        if (container && shortId) {

            let suffix = ""
            if (location.pathname.includes("admin/templates")) {
                suffix = "/admin/templates"
            }
            if (location.pathname.includes("user/dashboard")) {
                suffix = "/user/dashboard"
            }

            new DIYWebsiteBuilder({
                shortId,
                container,
                // sidebarColor: "#ECF0F4",
                // hoverColor: "#80a7ce",
                // primaryColor: "#80a7ce",
                // allowPageCRUD: false,
                initializePages: ["index"],
                exitURL: `${Config.WebURL}${suffix}`,
                apiKey: state.user?.apiKeys[0] || Config.APIKey,
                onPublish: (url, thumbnails) => {
                    console.log({ url })
                    console.log({ thumbnails })
                    const indexInAdminTemplate = state.resources.adminTemplates.findIndex(template => template.shortId === shortId)
                    if (indexInAdminTemplate > -1) {
                        setResources({ ...state.resources, adminTemplates: state.resources.adminTemplates.map((template, index) => index === indexInAdminTemplate ? ({ ...template, thumbnails, fullURL: url }) : template) })
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, shortId])

    return (
        <div id='container'></div>
    );
};

export default Build;
