"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// blur px max 10
// brightness min 0 max 2
// contrast min 0 max 2
// drop-shadow(2px 4px 6px rgba(0,0,0,0.1))
// hue-rotate(270deg) max 360
// grayscale min0, max 1
// invert min0, max 1
// opacity min0, max 1
// saturate min0, max 1
// sepia min0, max 1
