import { ReactNode } from "react";
import { Navbar } from "../Navbar";
import Footer from "../Footer/Footer";
import styled from "styled-components";
import { Group } from "../../layout/Group/Group";

const SkeletonWrapper = styled(Group)`
    min-height: 100vh;
    // background-color: ${props => props.theme.colors.primary};
`

export const Skeleton = ({ children }: { children: ReactNode }) => {
    return (
        <SkeletonWrapper direction="column">
            <Navbar />
            {children}
            <Footer />
        </SkeletonWrapper>
    )
};