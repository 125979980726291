"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ucFirst = void 0;
const ucFirst = (w) => {
    if (!w)
        return w;
    w = w.trim().toLowerCase();
    let f = w[0].toUpperCase();
    return f + w.substr(1);
};
exports.ucFirst = ucFirst;
