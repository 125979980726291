"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermission = void 0;
class UserPermission {
    user;
    constructor(user) {
        this.user = user;
    }
    canCreateProject(currentLength) {
        if (!this.user)
            return false;
        if (this.user.subscription.features.limits.projects === 0)
            return true;
        return this.user.subscription.features.limits.projects > currentLength;
    }
}
exports.UserPermission = UserPermission;
