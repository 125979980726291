"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordTypes = void 0;
var RecordTypes;
(function (RecordTypes) {
    RecordTypes["USER"] = "USER";
    RecordTypes["APIKEY"] = "APIKEY";
    RecordTypes["PROJECT"] = "PROJECT";
    RecordTypes["ADMINTEMPLATES"] = "ADMINTEMPLATES";
    RecordTypes["USERCONFIRMATION"] = "USERCONFIRMATION";
})(RecordTypes || (exports.RecordTypes = RecordTypes = {}));
