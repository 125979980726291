import styled from "styled-components";
import { boxShadow } from "../../elements";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Group } from "../../layout/Group/Group";
import { ArrowRight } from "react-bootstrap-icons";
import { Button } from "../../components/Form/Button";
import startBlankProject from "../../utils/startBlankProject.util";


const StyledTitle1 = styled.h1`
    font-size: 43px;
    font-weight: bold;
    text-align: center;
    span:nth-child(1) {
        color: ${(props) => props.theme.colors.black};
    }
    span:nth-child(2) {
        background: linear-gradient(to right, red, blue);
        background-clip: text;
        color: transparent;
    }
`

const StyledTitle3 = styled.h3`
    font-size: 22px;
    text-align: center;
    font-weight: lighter;
    color: ${(props) => props.theme.colors.gray};
`

const StyledGroup = styled(Group)`
    flex: 1;
    padding: 30px;
             
    .main-cta {
        ${boxShadow()};
        font-size: 18px;
        margin-top: 26px;
    }
`

const Home = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <StyledGroup align="center" justify="center" gap="xs">
            <StyledTitle1><span>{t('buildYourDreamWebsite')}</span> <span>{t('dragDropDone')}</span></StyledTitle1>
            <StyledTitle3>{t('noCodingNoProblem')}</StyledTitle3>
            <Button style={{ marginTop: '10px' }} className="main-cta" text={t('startBuildingForFree')} icon={<ArrowRight />} onClick={() => startBlankProject(navigate)} />
        </StyledGroup>
    );
};

export default Home;
