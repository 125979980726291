"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ltrim = ltrim;
function ltrim(str, char) {
    if (str && str.slice(0, char.length) === char) {
        return ltrim(str.slice(char.length), char);
    }
    else {
        return str;
    }
}
