import { ErrorPage } from "./ErrorPage";
import { useTranslation } from "react-i18next";

type FallbackProps = {
  error: {
    message: string;
  };
};

export const ErrorFallback = ({ error }: FallbackProps) => {
  return <ErrorPage message={error.message} />;
};

export const InternalServerError = () => {
  const { t } = useTranslation();
  return <ErrorPage message={t('internalServerError')} />;
};

export const NotFoundError = () => {
  const { t } = useTranslation();
  return <ErrorPage message={t('pageNotFound')} />;
};

export const AccessDeniedError = () => {
  const { t } = useTranslation();
  return <ErrorPage message={t('accessDenied')} />;
};
