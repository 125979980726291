"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentTypes = void 0;
var ComponentTypes;
(function (ComponentTypes) {
    ComponentTypes["Hero"] = "hero";
    ComponentTypes["Text"] = "text";
    ComponentTypes["List"] = "list";
    ComponentTypes["Links"] = "links";
    ComponentTypes["Icons"] = "icons";
    ComponentTypes["Image"] = "image";
    ComponentTypes["Video"] = "video";
    ComponentTypes["Navbar"] = "navbar";
    ComponentTypes["Footer"] = "footer";
    ComponentTypes["Control"] = "control";
    ComponentTypes["Buttons"] = "buttons";
    ComponentTypes["Divider"] = "divider";
    ComponentTypes["Features"] = "features";
    ComponentTypes["Container"] = "container";
    ComponentTypes["Testimonials"] = "testimonials";
})(ComponentTypes || (exports.ComponentTypes = ComponentTypes = {}));
