import { AuthContext } from '../context/auth';
import { useCallback, useContext } from 'react';

export const useRouteResolver = () => {

    const { setUser, state } = useContext(AuthContext);
    const dashboard = useCallback((): string => {
        if (state.user?.userTypes.length) {
            if (state.user.currentUserType && !state.user.userTypes.includes(state.user.currentUserType)) return `/access-denied`
            if (state.user.currentUserType) return `/${state.user.currentUserType}/dashboard`
            if (state.user.userTypes.length === 1) {
                setUser({ ...state.user, currentUserType: state.user.userTypes[0] })
                return `/${state.user.userTypes[0]}/dashboard`
            }
            return `/profiles`
        }
        return `/auth/sign-in`
    }, [setUser, state.user])

    return { dashboard }
}