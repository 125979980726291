import { useState } from "react";
import styled from "styled-components";
import { useApi } from "../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Group } from "../../layout/Group/Group";
import { forPhoneOnly, growSmaller } from "../../elements";
import { ArrowRight, Kanban } from "react-bootstrap-icons";
import startBlankProject from "../../utils/startBlankProject.util";
import { Button, OutlineButton } from "../../components/Form/Button";
import { intersection, Template, TemplateCategories, templateCategoriesArray } from "shared-library";


const StyledGroup = styled(Group)`
    padding: 20px;
    .title-group {
        padding: 60px 0;
        text-align: center;
        h1 {
            letter-spacing: 6px;
            color: ${props => props.theme.colors.black};
        }

        h5 {
            font-weight: 300;
            color: ${props => props.theme.colors.gray};
        }
    }

    .categories {
        padding: 50px 10px;
        button {
            font-size: 14px;
            padding: 6px 18px;
            cursor: pointer;
            ${growSmaller}
            background-color: transparent;
            color: ${props => props.theme.colors.gray};
            border: 1px solid ${props => props.theme.colors.gray10};

            &.active {
                color: ${props => props.theme.colors.gray};
                border-color: ${props => props.theme.colors.gray};
            }
        }
    }
`;

const TemplateGroup = styled(Group)`
    overflow: hidden;
    flex-grow: 1;
    width: 300px;
    max-width: 300px;
    padding: 10px 0;
    ${forPhoneOnly("max-width: 95%; margin: 0 auto;")}
    border: 1px solid ${props => props.theme.colors.gray10};
    ${growSmaller}

    &.deleting {
        opacity: 0.3;
        pointer-events: none;
    }

    .scrollable {
        overflow-y: auto;
        width: 100%;
        min-height: 350px;
        max-height: 350px;
        background-color: ${props => props.theme.colors.black};

        ${forPhoneOnly("max-height: 500px;")}
        box-sizing: content-box;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        img {
            width: 100%;
            margin-bottom: -10px;
        }

    }

    .details {
        padding: 12px;
        transition: all 1s;
        position: relative;

        span {
            font-weight: bold;
            color: ${props => props.theme.colors.gray};
        }

        .options {
            top: 8px;
            display: none;
            position: absolute;

            button {
                font-size: 14px;
                cursor: pointer;
                padding: 4px 20px;
                text-transform: uppercase;

                &:first-child {
                    border: 1px solid ${props => props.theme.colors.secondary};
                    background-color: ${props => props.theme.colors.secondary};
                    border: 1px solid ${props => props.theme.colors.secondary};
                }

                &:nth-child(2) {
                    color: ${props => props.theme.colors.gray};
                    border: 1px solid ${props => props.theme.colors.gray};
                    background-color: transparent;
                }                
            }
        }
    }

    &:hover {
        .details {
            .options {
                background-color: ${props => props.theme.colors.white};
                display: flex;
            }
        }
    }
`

const Templates = () => {

    const navigate = useNavigate();
    const { getTemplates } = useApi();
    const { t } = useTranslation();
    const [filters, setFilters] = useState<TemplateCategories[]>([]);

    const { data: templates } = useQuery({
        staleTime: Infinity,
        queryFn: getTemplates,
        queryKey: ["templates"],
        // enabled: !state.resources.adminTemplates?.length
    });

    const pushPopToFilters = (category: TemplateCategories) => {
        if (filters.includes(category)) {
            setFilters(filters.filter(c => c !== category));
            return
        }
        setFilters([...filters, category]);
    }

    return (
        <StyledGroup className="container-fluid">

            <Group className="container title-group" align="center" justify="center" gap="xs">
                <h1>{t('templateAggregate')}</h1>
                <h5>{t('withOurIntuitiveDragAndDropInterface')}</h5>
                <Group direction="row" gap="sm" justify="center" align="center" style={{ marginTop: '10px' }}>
                    <Button text={t('signUpForFree')} icon={<ArrowRight />} onClick={() => navigate("/auth/sign-up")} />
                    <OutlineButton text={t('startBlankProject')} icon={<Kanban />} onClick={() => startBlankProject(navigate)} />
                </Group>
            </Group>

            <Group gap="md" className="container">
                <Group className="categories" gap="xs" direction="row" align="center" justify="center">
                    {
                        templateCategoriesArray.map(category => <button key={category} onClick={() => pushPopToFilters(category)} className={filters.includes(category) ? 'active' : ''}>{t(category)}</button>)
                    }
                </Group>
                <Group className="template-container" direction="row" gap="md">
                    {
                        templates && templates.filter(t => !filters.length || intersection(t.categories, filters).length).map(template => <TemplateDom key={template.id} template={template} />)
                    }
                </Group>

            </Group>

        </StyledGroup>
    )
}

export default Templates;

const TemplateDom = ({ template }: { template: Template }) => {

    const { t } = useTranslation();
    // const navigate = useNavigate();

    const getStarted = () => {
        // navigate(dashboard, { state: { templateId: template.id } });
    }

    return (
        <TemplateGroup gap="none">
            <div className="scrollable">
                <img alt={t('templateScreenshot')} src={template.thumbnails.desktopFullPage || "/assets/images/diy-website-builder-dummy-image.png"} />
            </div>
            <Group className="details">
                <span>{template.title}</span>
                <Group align="center" justify="center" className="options" gap="xs" direction="row" wrap="nowrap">
                    <button onClick={() => window.open(template.fullURL, '_blank')?.focus()}>{t('preview')}</button>
                    <button onClick={getStarted}>{t('getStarted')}</button>
                </Group>
            </Group>
        </TemplateGroup>
    )
}