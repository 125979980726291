"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icons = void 0;
exports.Icons = {
    At: "bi-at",
    Hr: "bi-hr",
    Vr: "bi-vr",
    Pen: "bi-pen",
    Gem: "bi-gem",
    Sun: "bi-sun",
    Key: "bi-key",
    Dot: "bi-dot",
    App: "bi-app",
    Eye: "bi-eye",
    Send: "bi-send",
    Film: "bi-film",
    Star: "bi-star",
    Stars: "bi-stars",
    Stack: "bi-stack",
    Facebook: "bi-facebook",
    Twitter: "bi-twitter",
    Instagram: "bi-instagram",
    Threads: "bi-threads",
    Google: "bi-google",
    Paypal: "bi-paypal",
    Quora: "bi-quora",
    Alexa: "bi-alexa",
    Line: "bi-line",
    Spotify: "bi-spotify",
    Github: "bi-github",
    Discord: "bi-discord",
    Wordpress: "bi-wordpress",
    Reddit: "bi-reddit",
    Wechat: "bi-wechat",
    Slack: "bi-slack",
    Telegram: "bi-telegram",
    Behance: "bi-behance",
    Dribbble: "bi-dribbble",
    Linkedin: "bi-linkedin",
    Snapchat: "bi-snapchat",
    Whatsapp: "bi-whatsapp",
    StackOverflow: "bi-stack-overflow",
    Pinterest: "bi-pinterest",
    ThreadsFill: "bi-threads-fill",
    TwitterX: "bi-twitter-x",
    Cut: "bi-scissors",
    Bezier: "bi-bezier",
    Bezier2: "bi-bezier2",
    SegmentedNav: "bi-segmented-nav",
    LayersHalf: "bi-layers-half",
    CameraReels: "bi-camera-reels",
    StarFill: "bi-star-fill",
    StarHalf: "bi-star-half",
    Play: "bi-play",
    Crop: "bi-crop",
    Copy: "bi-copy",
    List: "bi-list",
    Bell: "bi-bell",
    Chat: "bi-chat",
    Grid: "bi-grid",
    Gear: "bi-gear",
    Hash: "bi-hash",
    Back: "bi-back",
    Tags: "bi-tags",
    Gift: "bi-gift",
    Lock: "bi-lock",
    Close: "bi-x-lg",
    H1: "bi-type-h1",
    H2: "bi-type-h2",
    H3: "bi-type-h3",
    H4: "bi-type-h4",
    H5: "bi-type-h5",
    H6: "bi-type-h6",
    Save: "bi-floppy",
    Brush: "bi-brush",
    Mouse: "bi-mouse",
    Phone: "bi-phone",
    Share: "bi-share",
    Cart3: "bi-cart3",
    Image: "bi-image",
    Mobile: "bi-phone",
    Delete: "bi-trash",
    Dash: "bi-dash-lg",
    Repeat: "bi-repeat",
    Globe2: "bi-globe2",
    People: "bi-people",
    Images: "bi-images",
    Folder: "bi-folder",
    ZoomIn: "bi-zoom-in",
    GeoAlt: "bi-geo-alt",
    PlusLg: "bi-plus-lg",
    Create: "bi-plus-lg",
    Sliders: "bi-sliders",
    Exclude: "bi-exclude",
    Check: "bi-check2-all",
    SquareHalf: "bi-square-half",
    Link: "bi-link-45deg",
    Desktop: "bi-display",
    Youtube: "bi-youtube",
    Balloon: "bi-balloon",
    SunFill: "bi-sun-fill",
    SlashLG: "bi-slash-lg",
    EVFront: "bi-ev-front",
    Container: "bi-square",
    ArrowUp: "bi-arrow-up",
    ZoomOut: "bi-zoom-out",
    Download: "bi-download",
    Asterisk: "bi-asterisk",
    Text: "bi-justify-left",
    TextEnd: "bi-text-right",
    Dashed: "bi-reception-0",
    AlignEnd: "bi-align-end",
    AlignTop: "bi-align-top",
    TextArea: "bi-textarea-t",
    Clipboard: "bi-clipboard",
    TextStart: "bi-text-left",
    ThreeDots: "bi-three-dots",
    TextJustify: "bi-justify",
    SendCheck: "bi-send-check",
    ChevronUp: "bi-chevron-up",
    Redo: "bi-arrow-clockwise",
    HeadsetVR: "bi-headset-vr",
    CodeSlash: "bi-code-slash",
    PersonAdd: "bi-person-add",
    ArrowLeft: "bi-arrow-left",
    ArrowDown: "bi-arrow-down",
    ArrowsMove: "bi-arrows-move",
    EnvelopeAt: "bi-envelope-at",
    PersonDown: "bi-person-down",
    PersonGear: "bi-person-gear",
    PersonLock: "bi-person-lock",
    InfoCircle: "bi-info-circle",
    PatchCheck: "bi-patch-check",
    ArrowRight: "bi-arrow-right",
    AlignStart: "bi-align-start",
    TextCenter: "bi-text-center",
    MenuButton: "bi-menu-button",
    QrCodeScan: "bi-qr-code-scan",
    CheckCircle: "bi-check-circle",
    Paragraph: "bi-text-paragraph",
    AspectRatio: "bi-aspect-ratio",
    ChevronDown: "bi-chevron-down",
    AlignCenter: "bi-align-center",
    AlignBottom: "bi-align-bottom",
    BoundingBox: "bi-bounding-box",
    FileTypeHTML: "bi-filetype-html",
    EnvelopePlus: "bi-envelope-plus",
    CircleSquare: "bi-circle-square",
    BalloonHeart: "bi-balloon-heart",
    AppIndicator: "bi-app-indicator",
    CloudArrowUp: "bi-cloud-arrow-up",
    HandThumbsUp: "bi-hand-thumbs-up",
    Undo: "bi-arrow-counterclockwise",
    ArrowBarLeft: "bi-arrow-bar-left",
    ArrowBarDown: "bi-arrow-bar-down",
    RocketTakeOff: "bi-rocket-takeoff",
    Settings: "bi-gear",
    Menu: "bi-menu-app",
    Home: "bi-house",
    Unlock: "bi-unlock",
    CardHeading: "bi-card-heading",
    BorderOuter: "bi-border-outer",
    BorderLeft: "bi-border-left",
    BorderRight: "bi-border-right",
    BorderTop: "bi-border-top",
    BorderBottom: "bi-border-bottom",
    ArrowLeftRight: "bi-arrow-left-right",
    ArrowDownUp: "bi-arrow-down-up",
    BoxArrowDownLeft: "bi-box-arrow-in-down-left",
    BoxArrowDownRight: "bi-box-arrow-in-down-right",
    Collection: "bi-collection",
    CollectionFill: "bi-collection-fill",
    CollectionPlay: "bi-collection-play",
    CollectionPlayFill: "bi-collection-play-fill",
    FileTypeZip: "bi-file-earmark-zip",
    BrowserChrome: "bi-browser-chrome",
    BrowserSafari: "bi-browser-safari",
    ArrowUpCircle: "bi-arrow-up-circle",
    ArrowsVertical: "bi-arrows-vertical",
    ClipboardCheck: "bi-clipboard-check",
    QuestionCircle: "bi-question-circle",
    HandIndexThumb: "bi-hand-index-thumb",
    HandThumbsDown: "bi-hand-thumbs-down",
    CloudArrowDown: "bi-cloud-arrow-down",
    CheckCircleFill: "bi-check-circle-fill",
    ChevronDoubleUp: "bi-chevron-double-up",
    ArrowDownCircle: "bi-arrow-down-circle",
    ArrowLeftCircle: "bi-arrow-left-circle",
    BoxArrowUpLeft: "bi-box-arrow-up-left",
    BoxArrowUpRight: "bi-box-arrow-up-right",
    AlignJustify: "bi-distribute-horizontal",
    ArrowRightCircle: "bi-arrow-right-circle",
    ExclamationCircle: "bi-exclamation-circle",
    CreditCard2Front: "bi-credit-card-2-front",
    ChevronDoubleLeft: "bi-chevron-double-left",
    ArrowsAngleExpand: "bi-arrows-angle-expand",
    ChevronDoubleDown: "bi-chevron-double-down",
    ExclamationOctagon: "bi-exclamation-octagon",
    BoundingBoxCircles: "bi-bounding-box-circles",
    ChevronDoubleRight: "bi-chevron-double-right",
    ExclamationTriangle: "bi-exclamation-triangle",
};
