"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomNumber = exports.randomID = void 0;
const randomID = (length = 12) => {
    let result = "";
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};
exports.randomID = randomID;
const randomNumber = (length = 12) => Math.floor(Math.random() * 10 ** length);
exports.randomNumber = randomNumber;
