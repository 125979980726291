"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLastIndex = void 0;
const findLastIndex = (array, predicate) => {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
};
exports.findLastIndex = findLastIndex;
