import { Spinner } from "./Button";
import styled from "styled-components";
import { grow, keyboardFocusOnly } from "../../../elements";
import { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
    text?: string;
    icon?: ReactNode;
    rightIcon?: ReactNode;
    color?: "primary" | "danger" | "success";
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    type?: "button" | "submit";
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    color: ${props => props.theme.colors.gray};
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.gray};
    padding: 0.8rem 2rem;
    min-height: 2.4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.2s;
    line-height: 1.2rem;
    overflow-wrap: break-word;
    ${grow}
    ${keyboardFocusOnly}

    &:hover {
        border-color: ${props => props.theme.colors.black};
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
    }

    &.success {
        color: ${(props) => props.theme.colors.success};
        border-color: ${(props) => props.theme.colors.success};
    
        .icon {
            color: ${(props) => props.theme.colors.success};
        }
    }

    &.danger {
        background-color: ${(props) => props.theme.colors.danger};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.danger};
        }
    }

    .text {
        overflow-wrap: break-word;
    }
    
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.colors.black};
    }

    &:disabled {
        opacity: .5;
        background-color: ${(props) => props.theme.colors.gray};
        color: ${(props) => props.theme.colors.primary};
        cursor: not-allowed;
    
        &.danger {
          background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger};
          }
        }

        &.success {
            color: ${(props) => props.theme.colors.success};
            background-color: ${(props) => props.theme.colors.success};
            .icon {
                color: ${(props) => props.theme.colors.success};
            }
          }
    
        &:active {
          transform: none;
        }
      }  
`

export const OutlineButton = (props: ButtonProps) => {
    const {
        text,
        icon,
        rightIcon,
        disabled = false,
        loading = false,
        color = "primary",
        style = {},
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", color];

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && icon && <div className="icon">{icon}</div>}
            {!loading && <div className="text">{text}</div>}
            {!loading && rightIcon && <div className="icon">{rightIcon}</div>}
        </StyledButton>
    );
};
