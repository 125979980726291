"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initControlSettings = exports.initDividerSettings = exports.initNavbarSettings = exports.initContainerSettings = exports.initImage = exports.initIconsSettings = exports.initListSettings = exports.initLinksSettings = exports.initButtonsSettings = exports.initVideoSettings = exports.initImageSettings = exports.initTextSettings = exports.initComponentSettings = exports.initFlexSettings = exports.initNewDocument = exports.getAnimationSettingsHoverStyles = exports.getStyleApplyTo = exports.getAnimationSettingsEntranceStyles = exports.getBorderStyles = exports.getComponentHrefLeadToType = exports.getMediaAssetCategories = exports.getDeviceTypes = void 0;
const random_util_1 = require("./random.util");
const colors_util_1 = require("./colors.util");
const componentType_enum_1 = require("../enums/componentType.enum");
const getDeviceTypes = () => ["desktop", "mobile"];
exports.getDeviceTypes = getDeviceTypes;
const getMediaAssetCategories = () => ["background", "business", "sales", "logo"];
exports.getMediaAssetCategories = getMediaAssetCategories;
const getComponentHrefLeadToType = () => ["URL", "control", "document", "list"];
exports.getComponentHrefLeadToType = getComponentHrefLeadToType;
const getBorderStyles = () => ["solid", "dashed", "dotted", "double", "groove", "ridge", "inset", "outset"];
exports.getBorderStyles = getBorderStyles;
const getAnimationSettingsEntranceStyles = () => ["slideInRight", "slideInLeft", "slideInDown", "slideInUp", "fadeInSlow"];
exports.getAnimationSettingsEntranceStyles = getAnimationSettingsEntranceStyles;
const getStyleApplyTo = () => ["all", "left", "right", "top", "bottom", "left+right", "top+bottom", "bottom+left", "bottom+right", "top+left", "top+right"];
exports.getStyleApplyTo = getStyleApplyTo;
const getAnimationSettingsHoverStyles = () => ["hoverEffect0", "hoverEffect1", "hoverEffect2", "hoverEffect3", "hoverEffect4", "hoverEffect5"];
exports.getAnimationSettingsHoverStyles = getAnimationSettingsHoverStyles;
const initNewDocument = (defaultFontId = 1) => {
    const componentId = (0, random_util_1.randomID)(10);
    return {
        title: "",
        fileName: "",
        isDefault: false,
        components: [
            {
                isMain: false,
                id: componentId,
                containable: false,
                type: componentType_enum_1.ComponentTypes.Text,
                settings: (0, exports.initComponentSettings)(true),
                elements: [
                    {
                        componentId,
                        id: (0, random_util_1.randomID)(),
                        textContent: 'Text',
                        type: componentType_enum_1.ComponentTypes.Text,
                        settings: {
                            textSettings: (0, exports.initTextSettings)(),
                            height: {
                                height: 100,
                                type: 'auto',
                                sizeIn: 'px',
                            },
                        },
                    }
                ]
            },
        ],
        id: (0, random_util_1.randomID)(),
        settings: {
            background: {
                type: 'color',
                allow: ["image", "color"],
                color: {
                    color: colors_util_1.Colors.white,
                    isTransparent: false,
                    canBeTransparent: false,
                    pattern: {
                        opacity: 0.25,
                        enabled: false,
                        pattern: colors_util_1.ColorPatternTypes.fortyFiveDegreeFabricDark
                    }
                },
                image: (0, exports.initImage)()
            },
            fontId: defaultFontId,
            paddings: {
                x: 0,
                y: 0,
                sizeIn: 'em',
            },
            childrenSettings: (0, exports.initFlexSettings)(true)
        }
    };
};
exports.initNewDocument = initNewDocument;
const initFlexSettings = (columnLayout = false, alignment = 'flex-start') => ({
    space: 0.25,
    alignment,
    sizeIn: 'em',
    columnLayout,
    inBuiltClasses: [],
    animation: {
        hover: {
            enable: false,
            style: "hoverEffect0",
        },
        entrance: {
            enable: false,
            delayInSeconds: 0.15,
            fillMode: 'forwards',
            durationInSeconds: 0.50,
            entranceMode: "formatted",
            animationName: "slideInUp",
            timingFunction: 'ease-in-out',
        }
    }
});
exports.initFlexSettings = initFlexSettings;
const initComponentSettings = (columnLayout = false, alignment = 'flex-start') => ({
    sizeIn: 'px',
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px'
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        applyTo: 'all',
        sizeIn: 'px',
        enable: false,
        style: 'solid'
    },
    height: {
        height: 200,
        type: 'auto',
        sizeIn: 'px',
    },
    hidden: false,
    inBuiltClasses: [],
    widthType: 'container',
    background: {
        allow: ["image", "color"],
        type: 'color',
        color: {
            color: colors_util_1.Colors.white,
            isTransparent: true,
            canBeTransparent: true
        },
        image: (0, exports.initImage)()
    },
    childrenSettings: (0, exports.initFlexSettings)(columnLayout, alignment)
});
exports.initComponentSettings = initComponentSettings;
const initTextSettings = (fontId = 1) => ({
    sizeIn: 'px',
    font: {
        id: fontId,
        useDefault: true
    },
    paddings: {
        x: 10,
        y: 10,
        sizeIn: 'px',
    },
    fontSize: 16,
    lineHeight: 30,
    letterSpacing: 0,
    textAlign: "start",
    elementType: 'div',
    fontWeight: "normal",
    borderRadius: 0,
    fontColor: {
        color: colors_util_1.Colors.black,
        isTransparent: false,
        canBeTransparent: false
    },
    background: {
        color: colors_util_1.Colors.white,
        isTransparent: true,
        canBeTransparent: true
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        applyTo: 'all',
        width: 1,
        sizeIn: 'px',
        enable: false,
        style: 'solid'
    }
});
exports.initTextSettings = initTextSettings;
const initImageSettings = () => ({
    altText: "",
    sizeIn: '%',
    width: 100,
    borderRadius: 0,
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    image: (0, exports.initImage)(),
    href: {
        lead: 'URL',
        toNewTab: false,
        isOptional: true,
        enableLinking: false,
        toURL: 'https://diy-website-builder.com'
    },
    elementType: 'img',
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        applyTo: 'all',
        enable: false,
        style: 'solid'
    }
});
exports.initImageSettings = initImageSettings;
const initVideoSettings = () => ({
    sizeIn: '%',
    width: 100,
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    controls: true,
    playMode: 'auto',
    type: 'embed',
    elementType: 'div',
    loop: false,
    allowFullscreen: true,
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        applyTo: 'all',
        sizeIn: 'px',
        enable: false,
        style: 'solid'
    }
});
exports.initVideoSettings = initVideoSettings;
const initButtonsSettings = (backgroundColor = colors_util_1.Colors.black, fontId = 1) => ({
    font: {
        id: fontId,
        useDefault: true
    },
    paddings: {
        x: 30,
        y: 10,
        sizeIn: 'px',
    },
    sizeIn: 'px',
    fontSize: 16,
    borderRadius: 0,
    elementType: 'a',
    icon: {
        space: 0.75,
        show: false,
        sizeIn: "rem",
        position: "left",
        canBeHidden: true,
        iconType: "ArrowRight",
    },
    fontColor: {
        color: colors_util_1.Colors.white,
        isTransparent: false,
        canBeTransparent: false
    },
    background: {
        isTransparent: false,
        color: backgroundColor,
        canBeTransparent: true
    },
    href: {
        lead: 'URL',
        toNewTab: false,
        isOptional: false,
        enableLinking: true,
        toURL: 'https://diy-website-builder.com'
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        enable: false,
        applyTo: 'all',
        style: 'solid'
    }
});
exports.initButtonsSettings = initButtonsSettings;
const initLinksSettings = (fontId = 1) => ({
    font: {
        id: fontId,
        useDefault: true
    },
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    borderRadius: 0,
    background: {
        color: colors_util_1.Colors.white,
        isTransparent: true,
        canBeTransparent: true
    },
    sizeIn: 'px',
    fontSize: 16,
    elementType: 'a',
    icon: {
        space: 0.75,
        show: false,
        sizeIn: "rem",
        position: "left",
        canBeHidden: true,
        iconType: "ArrowRight",
    },
    fontColor: {
        isTransparent: false,
        canBeTransparent: false,
        color: colors_util_1.Colors.lightBlue,
    },
    href: {
        lead: 'URL',
        toNewTab: false,
        isOptional: false,
        enableLinking: true,
        toURL: 'https://diy-website-builder.com'
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        applyTo: 'all',
        enable: false,
        style: 'solid'
    }
});
exports.initLinksSettings = initLinksSettings;
const initListSettings = (fontId = 1) => ({
    font: {
        id: fontId,
        useDefault: true
    },
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    sizeIn: 'px',
    fontSize: 16,
    elementType: 'a',
    letterSpacing: 0,
    textAlign: "start",
    fontWeight: "normal",
    borderRadius: 0,
    icon: {
        show: true,
        space: 0.75,
        sizeIn: "rem",
        position: "left",
        canBeHidden: true,
        iconType: "Dot",
    },
    fontColor: {
        color: colors_util_1.Colors.black,
        isTransparent: false,
        canBeTransparent: false,
    },
    href: {
        lead: 'URL',
        toNewTab: false,
        isOptional: true,
        enableLinking: false,
        toURL: 'https://diy-website-builder.com'
    },
    background: {
        color: colors_util_1.Colors.white,
        isTransparent: true,
        canBeTransparent: true
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        applyTo: 'all',
        enable: false,
        style: 'solid'
    }
});
exports.initListSettings = initListSettings;
const initIconsSettings = () => ({
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    sizeIn: 'px',
    fontSize: 28,
    elementType: 'a',
    background: {
        color: colors_util_1.Colors.white,
        isTransparent: true,
        canBeTransparent: true
    },
    icon: {
        space: 0.75,
        show: true,
        sizeIn: "rem",
        position: "left",
        canBeHidden: false,
        iconType: "ArrowRight",
    },
    borderRadius: 0,
    fontColor: {
        color: colors_util_1.Colors.black,
        isTransparent: false,
        canBeTransparent: false,
    },
    href: {
        lead: 'URL',
        toNewTab: false,
        isOptional: true,
        enableLinking: false,
        toURL: 'https://diy-website-builder.com'
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        applyTo: 'all',
        enable: false,
        style: 'solid'
    }
});
exports.initIconsSettings = initIconsSettings;
const initImage = () => ({
    clipPath: {
        type: 0,
        enabled: false
    },
    filter: {
        sepia: { value: 0.5 },
        invert: { value: 0.8 },
        contrast: { value: 1 },
        opacity: { value: 0.5 },
        grayscale: { value: 1 },
        saturate: { value: 0.5 },
        brightness: { value: 1 },
        dropShadow: {
            value: 2, sizeIn: 'px', color: {
                color: colors_util_1.Colors.lightestGrey,
                isTransparent: false,
                canBeTransparent: false
            }
        },
        blur: { value: 0.5, sizeIn: 'px' },
        hueRotate: { value: 0.5, sizeIn: 'deg' },
    }
});
exports.initImage = initImage;
const initContainerSettings = (backgroundColor = colors_util_1.Colors.lightGrey, width = 50) => ({
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    width,
    sizeIn: 'px',
    borderRadius: 0,
    elementType: 'div',
    background: {
        allow: ["image", "color"],
        type: 'color',
        color: {
            isTransparent: false,
            color: backgroundColor,
            canBeTransparent: true
        },
        image: (0, exports.initImage)()
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        applyTo: 'all',
        sizeIn: 'px',
        enable: false,
        style: 'solid'
    },
    childrenSettings: (0, exports.initFlexSettings)(true, "center")
});
exports.initContainerSettings = initContainerSettings;
const initNavbarSettings = (width = 15) => ({
    paddings: {
        x: 0,
        y: 0,
        sizeIn: 'px',
    },
    width,
    sizeIn: 'px',
    style: "style1",
    borderRadius: 0,
    elementType: 'div',
    defaultBehavior: 'desktop',
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        width: 1,
        sizeIn: 'px',
        enable: false,
        style: 'solid',
        applyTo: 'all',
    },
    mobileHandleColor: {
        color: colors_util_1.Colors.black,
        isTransparent: false,
        canBeTransparent: false
    },
});
exports.initNavbarSettings = initNavbarSettings;
const initDividerSettings = (backgroundColor = colors_util_1.Colors.black, width = 100) => ({
    width,
    height: 1,
    sizeIn: 'px',
    borderRadius: 0,
    elementType: 'div',
    background: {
        isTransparent: false,
        color: backgroundColor,
        canBeTransparent: true
    },
    border: {
        color: {
            color: colors_util_1.Colors.black,
            isTransparent: false,
            canBeTransparent: false
        },
        applyTo: 'all',
        width: 1,
        sizeIn: 'px',
        enable: false,
        style: 'solid'
    }
});
exports.initDividerSettings = initDividerSettings;
const initControlSettings = (backgroundColor = colors_util_1.Colors.red) => ({
    background: {
        isTransparent: false,
        color: backgroundColor,
        canBeTransparent: true
    },
    controlType: 'section',
    elementType: 'section',
});
exports.initControlSettings = initControlSettings;
