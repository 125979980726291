import styled from "styled-components";
import { randomID } from "shared-library";
import { useNavigate } from "react-router-dom";
import { Group } from "../../layout/Group/Group";
import { ArrowRight } from "react-bootstrap-icons";
import { Button } from "../../components/Form/Button";
import { DiskManager } from "../../utils/diskManager";
import { useTranslation } from "react-i18next";


const StyledTitle1 = styled.h1`
    font-size: 43px;
    font-weight: bold;
    text-align: center;
    color: ${props => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.primary};

    span {
        color: ${(props) => props.theme.colors.primary};
    }
`

const StyledTitle3 = styled.h3`
    font-size: 26px;
    text-align: center;
    font-weight: lighter;
    color: ${props => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.primary};
`

const StyledGroup = styled(Group)`
    flex: 1;
    padding: 30px;

    .main-cta {
        font-size: 18px;
        margin-top: 26px;
    }
`

const ComingSoon = () => {

    const navigate = useNavigate()
    const { t } = useTranslation();

    const onGetStartedClicked = () => {
        const diskManager = new DiskManager("lastSID");
        const savedValue = diskManager.retrieve()
        let shortId = "";
        if (savedValue) {
            try {
                let sv = JSON.parse(savedValue) as { shortId: string; }
                shortId = sv.shortId
            } catch (error) {
            }
        }
        if (!shortId) {
            shortId = randomID(10);
            diskManager.save(JSON.stringify({ shortId }));
        }
        navigate(`/build/${shortId}`);
    }

    return (
        <StyledGroup align="center" justify="center" gap="xs">
            <StyledTitle1>{t('comingSoon')}</StyledTitle1>
            <StyledTitle3>{t('noCodingNoProblem')}</StyledTitle3>
            <Button style={{ marginTop: '20px' }} className="main-cta" text={t('startBuildingForFree')} icon={<ArrowRight />} onClick={onGetStartedClicked} />
        </StyledGroup>
    );
};

export default ComingSoon;
