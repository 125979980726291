import { randomID } from "shared-library";
import { DiskManager } from "./diskManager";
import { NavigateFunction } from "react-router-dom";

const startBlankProject = (navigate: NavigateFunction) => {
    const diskManager = new DiskManager("lastSID");
    const savedValue = diskManager.retrieve()
    let shortId = "";
    if (savedValue) {
        try {
            let sv = JSON.parse(savedValue) as { shortId: string; }
            shortId = sv.shortId
        } catch (error) {
        }
    }
    if (!shortId) {
        shortId = randomID().toLowerCase();
        diskManager.save(JSON.stringify({ shortId }));
    }
    navigate(`/build/${shortId}`);
}

export default startBlankProject