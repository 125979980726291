"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPatternTypes = exports.Colors = void 0;
exports.Colors = {
    red: "#e55252",
    red10: "#FDEEEE",
    grey: "#CCCCCC",
    grey80: "#CACACA",
    grey10: "#FAFAFA",
    gray: "#5b5b55",
    white: "#FFFFFF",
    green: "#0e7503",
    green10: "#E7F2E6",
    orange: "#D09218",
    orange10: "#FBF5E8",
    black: "#252525",
    faded50: "#93948F",
    primary: "#262626",
    faded100: "#EFEFEF",
    lightGrey: "#f5f5f5",
    lightBlue: "#4C1CB3",
    lightGrey10: "#F6F6F6",
    lighterGrey: "#f1f1f1",
    lightestGrey: "#f8f8f8",
    lightestGray: "#f9f9f9",
    fadedPrimary: "#E3E3E3",
};
var ColorPatternTypes;
(function (ColorPatternTypes) {
    ColorPatternTypes["fortyFiveDegreeFabricDark"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-45-degree-fabric-dark.png";
    ColorPatternTypes["absurdity"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-absurdity.png";
    ColorPatternTypes["agSquare"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-ag-square.png";
    ColorPatternTypes["arches"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-arches.png";
    ColorPatternTypes["axiomPattern"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-axiom-pattern.png";
    ColorPatternTypes["batthern"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-batthern.png";
    ColorPatternTypes["blackOrchid"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-black-orchid.png";
    ColorPatternTypes["brickWallDark"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-brick-wall-dark.png";
    ColorPatternTypes["brickWall"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-brick-wall.png";
    ColorPatternTypes["brilliant"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-brilliant.png";
    ColorPatternTypes["brokenNoise"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-broken-noise.png";
    ColorPatternTypes["climpek"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-climpek.png";
    ColorPatternTypes["cubes"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-cubes.png";
    ColorPatternTypes["debutDark"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-debut-dark.png";
    ColorPatternTypes["diagmondsLight"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-diagmonds-light.png";
    ColorPatternTypes["diagmonds"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-diagmonds.png";
    ColorPatternTypes["escheresqueDark"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-escheresque-dark.png";
    ColorPatternTypes["escheresque"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-escheresque.png";
    ColorPatternTypes["fabricOfSquares"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-fabric-of-squares.png";
    ColorPatternTypes["footballNoLines"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-football-no-lines.png";
    ColorPatternTypes["gplay"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-gplay.png";
    ColorPatternTypes["hixsEvolution"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-hixs-evolution.png";
    ColorPatternTypes["inspirationGeometry"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-inspiration-geometry.png";
    ColorPatternTypes["lightHoneycombDark"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-light-honeycomb-dark.png";
    ColorPatternTypes["lightHoneycomb"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-light-honeycomb.png";
    ColorPatternTypes["lightSketch"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-light-sketch.png";
    ColorPatternTypes["mazeBlack"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-maze-black.png";
    ColorPatternTypes["moulin"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-moulin.png";
    ColorPatternTypes["noisyNet"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-noisy-net.png";
    ColorPatternTypes["oldMoon"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-old-moon.png";
    ColorPatternTypes["polonezPattern"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-polonez-pattern.png";
    ColorPatternTypes["simpleDashed"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-simple-dashed.png";
    ColorPatternTypes["stardust"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-stardust.png";
    ColorPatternTypes["starring"] = "https://cdn.diy-website-builder.com/assets/photos/diy-website-builder-pattern-starring.png";
})(ColorPatternTypes || (exports.ColorPatternTypes = ColorPatternTypes = {}));
