"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgba = void 0;
const hexToRgba = (hex, percentage = 100) => {
    // Remove the leading # if present
    hex = hex.replace(/^#/, '');
    // Handle 3-digit shorthand (e.g. #abc)
    if (hex.length === 3) {
        hex = hex.split('').map(x => x + x).join('');
    }
    // Handle 7 or 9-digit hex (e.g. #1234567 or #12345678)
    let alpha = 1; // Default alpha
    // console.log({ hex })
    if (hex.length === 7) {
        // Last digit represents the alpha as a percentage (0-9 becomes 0%-90%)
        alpha = parseInt(hex.slice(-1), 16) / 15; // Map 0-15 to 0-1 range
        hex = hex.slice(0, 6); // Get the first 6 hex digits for RGB
    }
    else if (hex.length === 9) {
        // Last 2 digits represent the alpha in hex (00-FF becomes 0-1)
        alpha = parseInt(hex.slice(-2), 16) / 255;
        hex = hex.slice(0, 6); // Get the first 6 hex digits for RGB
    }
    const r = parseInt(hex.substring(0, 2), 16), g = parseInt(hex.substring(2, 4), 16), b = parseInt(hex.substring(4, 6), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + percentage + '%)';
};
exports.hexToRgba = hexToRgba;
