import styled from "styled-components";
import styles from "./Spinner.module.scss";
import { grow, keyboardFocusOnly } from "../../../elements";
import { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
    text?: string;
    icon?: ReactNode;
    className?: string;
    rightIcon?: ReactNode;
    color?: "primary" | "danger" | "success" | "secondary";
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    type?: "button" | "submit";
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    background-color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};
    padding: 0.8rem 2rem;
    min-height: 2.4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.2s;
    line-height: 1.2rem;
    overflow-wrap: break-word;
    ${grow}
    ${keyboardFocusOnly}
    &:hover {
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
        background-color: ${(props) => props.theme.colors.primary};
    }

    &.success {
        background-color: ${(props) => props.theme.colors.success};
    }

    &.danger {
        background-color: ${(props) => props.theme.colors.danger};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.danger};
        }
    }

    &.secondary {
        background-color: ${(props) => props.theme.colors.secondary};
    }

     &:disabled {
        opacity: .6;
        background-color: ${(props) => props.theme.colors.gray};
        color: ${(props) => props.theme.colors.primary};
        cursor: not-allowed;
    
        &.danger {
            color: ${props => props.theme.colors.black};
            background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger};
          }
        }

        &.success {
            color: ${props => props.theme.colors.black};
            background-color: ${(props) => props.theme.colors.success};
          }
    
        &:active {
          transform: none;
        }
    }  
`

export const Button = (props: ButtonProps) => {
    const {
        text,
        icon,
        rightIcon,
        className = "",
        disabled = false,
        loading = false,
        color = "primary",
        style = {},
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", color, className].filter(cn => cn);

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && icon && <>{icon}</>}
            {!loading && <>{text}</>}
            {!loading && rightIcon && <>{rightIcon}</>}
        </StyledButton>
    );
};


const StyledSpinnerDiv = styled.div`
    background: ${(props) => props.theme.colors.white};
`

export const Spinner = () => {
    return (
        <div className={styles.ellipsis}>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
        </div>
    );
};
