import { Config } from "../config";
import { useContext } from "react";
import { useFetch } from "./use-fetch";
import { AuthContext } from "../context/auth";
import { useConsoleLog } from "./use-console-log.hook";
import { TemplateRequest } from "../utils/types/template-request.interface";
import { RequestResponse, Template, UserDetails, UserConfirmationCodeType, ProjectDetails } from "shared-library";


interface ResetPasswordParams {
    code: string;
    email: string;
    password: string;
}

interface SignUpParams extends ResetPasswordParams {
    fullName: string;
}
export const useApi = () => {

    const { log } = useConsoleLog();
    const { get, remove, post, patch } = useFetch();
    const { state, setResources } = useContext(AuthContext);

    const getSessionDetails = async ({ jwt }: { jwt: string; }): Promise<UserDetails> => {
        log(jwt)
        const { data } = await get<RequestResponse<UserDetails>>(`${Config.APIBaseUrl}auth/session`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
        log({ getSessionDetails: data })
        return data
    }

    const signUp = async (signUpParams: SignUpParams): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/sign-up`, signUpParams);
        log({ signUp: data })
        return data.data
    }

    const resetPassword = async (resetPasswordParams: ResetPasswordParams): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/password-reset`, resetPasswordParams);
        log({ resetPassword: data })
        return data.data
    }

    const sendAuthenticationCode = async (param: { email: string; codeType: UserConfirmationCodeType }): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}public/confirmation-code`, param);
        log({ sendAuthenticationCode: data })
        return data.data
    }

    const getTemplates = async (): Promise<Template[]> => {
        const { data } = await get<RequestResponse<Template[]>>(`${Config.APIBaseUrl}public/templates`);
        // setResources({ ...state.resources, adminTemplates: data });
        log({ getTemplates: data })
        return data
    }

    const getAdminTemplates = async (): Promise<Template[]> => {
        const { data } = await get<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates`);
        setResources({ ...state.resources, adminTemplates: data });
        log({ getAdminTemplates: data })
        return data
    }

    const createProject = async ({ templateId = "", shortId = "" }: { templateId?: string; shortId?: string }): Promise<{ shortId: string; }> => {
        const { data } = await post<RequestResponse<{ shortId: string; }>>(`${Config.APIBaseUrl}user/projects?templateId=${templateId}&shortId=${shortId}`, {});
        log({ createProject: data })
        return data.data
    }

    const getProjects = async (): Promise<ProjectDetails[]> => {
        const { data } = await get<RequestResponse<ProjectDetails[]>>(`${Config.APIBaseUrl}user/projects`);
        setResources({ ...state.resources, projects: data });
        log({ getProjects: data })
        return data
    }

    const duplicateProject = async (shortId: string): Promise<{ shortId: string; }> => {
        const { data } = await get<RequestResponse<{ shortId: string; }>>(`${Config.APIBaseUrl}user/duplicate-project/${shortId}`);
        log({ duplicateProject: data })
        return data
    }

    const deleteProject = async (projectId: string): Promise<boolean> => {
        const { data } = await remove<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/projects/${projectId}`);
        getProjects()
        log({ deleteProject: data })
        return data.data
    }

    const createAdminTemplate = async (newAdminTemplate: TemplateRequest): Promise<Template[]> => {
        const { data } = newAdminTemplate.id ? await patch<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${newAdminTemplate.shortId}`, newAdminTemplate) : await post<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${newAdminTemplate.shortId}`, newAdminTemplate);
        log({ createAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    const duplicateAdminTemplate = async (shortId: string, newAdminTemplate: Template): Promise<Template[]> => {
        const { data } = await post<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/duplicate-template/${shortId}`, { templateToDuplicateId: newAdminTemplate.id });
        log({ duplicateAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    const deleteAdminTemplate = async (shortId: string): Promise<Template[]> => {
        const { data } = await remove<RequestResponse<Template[]>>(`${Config.APIBaseUrl}admin/templates/${shortId}`);
        log({ deleteAdminTemplate: data })
        setResources({ ...state.resources, adminTemplates: data.data });
        return data.data
    }

    return {
        signUp,
        getProjects,
        getTemplates,
        createProject,
        resetPassword,
        deleteProject,
        duplicateProject,
        getSessionDetails,
        getAdminTemplates,
        createAdminTemplate,
        deleteAdminTemplate,
        duplicateAdminTemplate,
        sendAuthenticationCode
    }
}