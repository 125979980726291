import { Button } from "../Form/Button";
import styles from "./Navbar.module.scss";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";
import { AuthContext } from "../../context/auth";
import styled, { useTheme } from "styled-components";
import { createImageFromInitials } from "shared-library";
import { useContext, useEffect, useRef, useState } from "react";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { DoorClosed, HandIndexThumb, Person } from "react-bootstrap-icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { boxShadow, forPhoneOnly, StyledUserPicture } from "../../elements";


const StyledNav = styled.nav`
    ${boxShadow()};
    padding: 14px 0px 14px 0px;

    .inner {
        ${forPhoneOnly("padding: 0 1rem;")}
    }

    .navLinks {
        .navLink {
            font-size: 14px;
            position: relative;
            padding-bottom: 4px;
            text-decoration: none;
            transition: all 0.25s;
            border-bottom: 4px solid transparent;
            color: ${props => props.theme.colors.black};

            button {
                ${boxShadow()};
            }
            
            &.active {
                border-bottom-color: ${(props) => props.theme.colors.secondary};
            }
        }

        .profile-dropdown-photo {
            position: relative;
            img {
                margin-top: 3px;
            }
            .profile-dropdown {
                top: 58px;
                z-index: 1;
                right: 0px;
                width: 200px;
                ${boxShadow()};
                padding: 10px 0;
                position: absolute;
                ${forPhoneOnly("right: -80px;")}
                background-color: ${(props) => props.theme.colors.white};

                .dropdown-link {
                    gap: 0.5rem;
                    font-size: 14px;
                    cursor: pointer;
                    padding: 10px 14px;
                    align-items: center;
                    text-decoration: none;
                    color: ${(props) => props.theme.colors.gray};
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    transition: 0.25s;
                    letter-spacing: 2px;

                    &:hover {
                        color: ${props => props.theme.colors.black};
                        background-color: ${(props) => props.theme.colors.secondary};
                    }

                    &:last-child {
                        border-top: 1px solid ${(props) => props.theme.colors.gray10};
                    }
                }
            }
        }
    }

    input[type=checkbox] { display: none; }
    .wblnavToggler {
        display: none;
    }
    @media (max-width: 599px) {
        .wblnavToggler {
            display: block;
            label div {
                max-width: 35px;
                cursor: pointer;
                min-height: 2px;
                background-color: ${props => props.theme.colors.black};
            }
        }
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(2) {
            width: 35px;
            margin: 4px 0;
        }
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(1),
        input[type=checkbox]:not(:checked)~.wblnavToggler label div:nth-child(3) {
            width: 25px;
            transition: 0.3s;
            margin-left: 5px;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(2) {
            background-color: transparent;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(1) {
            width: 35px;
            margin-left: -40px;
            transform: rotate(45deg);
            transition: 0.3s;
        }
        input[type=checkbox]:checked~.wblnavToggler label div:nth-child(3) {
            width: 35px;
            transform: rotate(-45deg);
            margin-top: -4px;
            margin-left: -40px;
            transition: 0.3s;
        }       
        input[type=checkbox]:not(:checked)~.navLinks {
            display: none;
        }               
        input[type=checkbox]:checked~.navLinks {
            display: flex;
            flex-direction: column !important;
            position: absolute;
            top: 90px;
            padding: 30px;
            width: 90%;
            margin: 0 auto;
            background-color: ${(props) => props.theme.colors.white};
        }   
    }   
`

export const Navbar = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { dashboard } = useRouteResolver();
    const { state, logout } = useContext(AuthContext);
    const profileDropDownRef = useRef<HTMLDivElement>(null);
    const [showingProfileDropdown, setShowingProfileDropdown] = useState(false);
    const handleClickOutside = (event: MouseEvent) => {
        if (profileDropDownRef.current && !profileDropDownRef.current.contains(event.target as Node)) {
            setShowingProfileDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <StyledNav className={`${styles.navMenu}`}>
            <Group direction="row" justify="space-between" align="center" className={`inner container ${styles.inner}`}>
                <input id="nav_checkvzletWLVeONp7paw1PUYyab7" type="checkbox" />
                <Link to="/" className={styles.navLogo} role="logo">
                    <img src="/logo.png" width="100%" alt="Company logo" />
                </Link>
                <Group className="navLinks" gap="md" align="center" direction="row" justify="center">
                    {
                        state.user ? (
                            <>
                                {
                                    state.user.currentUserType === "admin" &&
                                    <>
                                        <NavLink to="/admin/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                    </>
                                }

                                {
                                    state.user.currentUserType === "user" &&
                                    <>
                                        <NavLink to="/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                    </>
                                }

                                {
                                    state.user.currentUserType && !location.pathname.includes(state.user.currentUserType) && <Button onClick={() => navigate(dashboard())} text={t('dashboard')} icon={<HandIndexThumb />} />
                                }

                                <div className="profile-dropdown-photo" ref={profileDropDownRef}>
                                    <StyledUserPicture onClick={() => setShowingProfileDropdown(!showingProfileDropdown)} src={state.user?.picture || createImageFromInitials(state.user?.fullName || "Unknown User", theme.colors.black, theme.colors.secondary, 28)} alt={t('profilePicture')} />
                                    {
                                        showingProfileDropdown && <div className="profile-dropdown">
                                            <Group gap="none">
                                                <Link to="/profile" className="dropdown-link"><Person size={18} /> {t('profile')}</Link>
                                                <span onClick={logout} className="dropdown-link"><DoorClosed size={18} /> {t('signOut')}</span>
                                            </Group>
                                        </div>
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <NavLink to="/templates" className={(navData) => (navData.isActive ? 'active' : '') + " navLink"}>{t('templates')}</NavLink>
                                <Button onClick={() => navigate("/auth/sign-in")} text={t('signIn')} icon={<HandIndexThumb />} />
                            </>
                        )
                    }
                </Group>
                <div className="wblnavToggler">
                    <label htmlFor="nav_checkvzletWLVeONp7paw1PUYyab7">
                        <div></div>
                        <div></div>
                        <div></div>
                    </label>
                </div>
            </Group>
        </StyledNav>
    );
};
