"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extensionToContentType = exports.ExtensionLibrary = void 0;
exports.ExtensionLibrary = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    tiff: 'image/tiff',
    webp: 'image/webp',
    mp4: 'video/mp4',
    webm: 'video/webm',
    pdf: 'application/pdf',
    flv: 'video/x-flv',
    wmv: 'video/x-ms-wmv',
    mov: 'video/quicktime',
    mkv: 'video/x-matroska',
    avi: 'video/x-msvideo',
    html: 'text/html',
};
const extensionToContentType = (extension) => exports.ExtensionLibrary[extension];
exports.extensionToContentType = extensionToContentType;
