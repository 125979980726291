
export const themes = [
    {
        colors: {
            white: '#FDFEFF',
            gray: '#515151',
            gray10: '#EEEEEE',
            black: '#252525',
            danger: '#DF4725',
            primary: '#AC8968',
            secondary: '#ffd700',
            success: '#51B849',
        },
        isDefault: true,
        id: "default"
    }
]
