import { Link } from "react-router-dom";
import { StyledH4 } from "../../elements";
import { Page } from "../../layout/Page/Page";
import { useTranslation } from "react-i18next";
import { Group } from "../../layout/Group/Group";

type ErrorType = {
  message: string;
};

export const ErrorPage = ({ message }: ErrorType) => {
  const { t } = useTranslation();
  return (
    <Page>
      <Group gap="md" style={{ maxWidth: 700, margin: "60px auto" }}>
        <StyledH4>{message}</StyledH4>
        <Link to="/" role="button" className="btn">
          <div className="text">{t('goHome')}</div>
        </Link>
      </Group>
    </Page>
  );
};
