import { UserType } from "shared-library";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { ReactElement, useContext } from "react";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";

interface PrivateRouteProps {
    children: ReactElement;
    strictlyForUser?: UserType;
}
export const PrivateRoute = ({ children, strictlyForUser }: PrivateRouteProps) => {
    const { state } = useContext(AuthContext);
    if (!state.user) return <Navigate to="/auth/sign-in" replace />;
    if (strictlyForUser && !state.user.userTypes.includes(strictlyForUser)) return <Navigate to="/access-denied" replace />;
    return children;
};


interface PublicRouteProps {
    children: ReactElement;
}
export const PublicRoute = ({ children }: PublicRouteProps) => {
    const { dashboard } = useRouteResolver()
    const { state } = useContext(AuthContext);

    if (state.user) {
        return <Navigate to={dashboard()} replace />;
    }

    return children;
};
